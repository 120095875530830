import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { CenterStick } from '../global/svgs';
import { DisplayLTV, showWhichTD, priceTag, trimAddress, limitStringLength } from '../utils/functions';

const GradientLine = () => (
	<div className="invest-line-wrapper mb-1">
		<img alt="gradient background" src="/icons/rectangle-gradient.png" width="75" height="3" />
	</div>
);

const Invest = ({ properties }) => {
	return (
		<section id="home-invest">
			<div className="bg-illust-wrapper">
				<figure className="left-waffle" />
				<figure className="right-waffle" />
			</div>
			<Container fluid className="py-5">
				<Row className="justify-content-center">
					<Col xs={10} className="invest-wrapper">
						<h2 className="invest-headline">Available Properties to Invest</h2>
						<div className="mt-2">
							<CenterStick />
						</div>

						<Carousel indicators={false} interval={null}>
							{properties.map((property, index) => (
								<Carousel.Item key={index}>
									<Container fluid className="my-3 invest-card-wrapper">
										<Row className="mt-5 invest-card-row">
											{property.map((singleProp, i) => (
												<Col xs={12} sm={12} md={6} lg={4} xl={3} key={i} className="invest-card-col px-2">
													{singleProp ? (
														<Link to={`/property/${singleProp._id}`} className="text-decoration-none">
															<div className="invest-card-individual">
																{singleProp.images.length > 0 ? (
																	<img
																		alt={'property'}
																		src={singleProp.images[0] ? singleProp.images[0] : '/property/image-from-rawpixel-id-558306-jpeg.jpg'}
																		width="100%"
																		height="160px"
																		className="invest-property-img"
																		onError={e => {
																			e.target.onerror = null;
																			e.target.src = '/property/image-from-rawpixel-id-558306-jpeg.jpg';
																		}}
																	/>
																) : (
																	<img
																		alt="property placeholder"
																		key="no-image"
																		src="/property/image-from-rawpixel-id-558306-jpeg.jpg"
																		width="100%"
																		height="160px"
																		className="invest-property-img"
																		onError={e => {
																			e.target.onerror = null;
																			e.target.src = '/property/image-from-rawpixel-id-558306-jpeg.jpg';
																		}}
																	/>
																)}{' '}
																{singleProp.currentEstValue ? <div className="apv">Est. Value: {priceTag(singleProp.currentEstValue)}</div> : ''}
																<div className="invest-card-subhead-wrapper">
																	<span className="mt-2 mb-1 invest-card-subhead text-capitalize"></span>
																</div>
																<span className="invest-card-head text-capitalize">
																	{limitStringLength(
																		22,
																		(singleProp.subjectPropertyAddr.line1 ? trimAddress(singleProp.subjectPropertyAddr) + ', ' : '') +
																		(singleProp.subjectPropertyAddr.city ? singleProp.subjectPropertyAddr.city + ', ' : '') +
																		(singleProp.subjectPropertyAddr.state ? <span className="text-uppercase">{singleProp.subjectPropertyAddr.state + ' '}</span> : '') +
																		(singleProp.subjectPropertyAddr.zip ? singleProp.subjectPropertyAddr.zip : '')
																	)}
																</span>
																<GradientLine />
																<ul className="no-bullet invest-card-detail mb-0">
																	<li>
																		Loan Amt:{' '}
																		<span className="bold-black text-capitalize invest-loantype-nowrap">
																			{singleProp.loanType === 'fix & flip/Rehab'
																				? priceTag(singleProp.loanAmt + singleProp.fixUpCost)
																				: singleProp.loanAmt
																					? priceTag(singleProp.loanAmt)
																					: '-'}
																		</span>
																	</li>
																	<li>
																		<DisplayLTV singleProp={singleProp} color="black" />
																	</li>
																	<li>
																		Rate Req.:{' '}
																		<span className="bold-black">{singleProp.interestRate ? singleProp.interestRate + '%' : ''}</span>
																	</li>
																	<li>
																		Term Req.: <span className="bold-black">{singleProp.term ? singleProp.term : ''}</span>
																	</li>
																	<li>
																		Loan Type: <span className="bold-black text-capitalize invest-loantype-nowrap">{singleProp.loanType}</span>
																	</li>
																	{
																		(singleProp.loanType === '2nd' || singleProp.loanType === '3rd') ? <span className="bold-black text-capitalize invest-loantype-nowrap">&nbsp;</span> :
																			<li>
																				Lien POS.:{' '}
																				<span className="bold-black text-capitalize invest-loantype-nowrap">
																					{singleProp.lienPos ? singleProp.lienPos : showWhichTD(singleProp.mortgageBalance.index)}
																				</span>
																			</li>
																	}
																</ul>
																{/* <div className="invest-amt-wrapper">
																	<span className="invest-amt-key">Loan amount</span>
																	<span className="invest-amt-val">{singleProp.loanAmt ? `${priceTag(singleProp.loanAmt)}` : '-'}</span>
																</div> */}
															</div>
														</Link>
													) : (
														<div className="invest-card-individual no-new-listing">
															<span className="no-new-listing-text">No New Listing</span>
														</div>
													)}
												</Col>
											))}
										</Row>
									</Container>
								</Carousel.Item>
							))}
						</Carousel>
						<div className="carousel-custom-control-wrapper">
							<Button className="carousel-custom-control-prev" onClick={e => document.querySelector('.carousel-control-prev').click()}>
								<svg width="53" height="17" viewBox="0 0 53 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M52 12.1675C52 12.1675 42.3785 4.33641 33.7139 2.99912C23.8969 1.48397 9.4224 8.37607 9.4224 8.37607V1L2 14.5802L15.8327 11.4782"
										stroke="#303030"
										strokeWidth="2"
									/>
								</svg>
							</Button>
							<Button className="carousel-custom-control-next" onClick={e => document.querySelector('.carousel-control-next').click()}>
								<svg width="53" height="17" viewBox="0 0 53 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1 12.1675C1 12.1675 10.6215 4.33641 19.2861 2.99912C29.1031 1.48397 43.5776 8.37607 43.5776 8.37607V1L51 14.5802L37.1673 11.4782"
										stroke="#303030"
										strokeWidth="2"
									/>
								</svg>
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Invest;
