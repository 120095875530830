import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { CenterStick, BRStick } from '../components/global/svgs';
import { Link } from 'react-router-dom';
import { Arrowhead } from '../components/global/svgs';
import Layout from '../components/global/Layout';

const AboutUsPage = props => {
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">About us - Yoonimo</title>
				<link data-react-helmet="true" rel="canonical" href="https://yoonimo.com/about-us/" />
				<meta data-react-helmet="true" property="og:locale" content="en_US" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="About Us - Yoonimo" />
				<meta
					data-react-helmet="true"
					property="og:description"
					content="The mission of Yoonimo is to provide investors and beneficiaries with the best possible trust deed investment opportunities. We can bring you the best deals in high return investment funds, commercial real estate loans, and investment properties in California."
				/>
				<meta data-react-helmet="true" property="og:url" content="/about-us/" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
				<meta data-react-helmet="true" property="og:image" content="/about/ezgif.com-webp-to-jpg.jpg" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
			</Helmet>
			<div className="bg-illust-wrapper">
				<figure className="AboutUs-top-spill" />
				<figure className="AboutUs-bottom-spill" />
			</div>
			<section id="about-us">
				<Container>
					<Row className="mt-71p">
						<Col className="text-center">
							<h2 className="headline title1">About Us</h2>
							<span className="subhead retina-font">Invest in places that matter to you.</span>
							<div className="mt-2">
								<CenterStick />
							</div>
							<Col className="col-lg-10 offset-lg-1 retina-font">
								<p className="mt-3">
									Yoonimo is an expert in both <strong>Residential</strong> and <strong>Commercial</strong> real estate lending through its vast networks of
									investors and funding sources. Our experience, readiness, flexibility, and capability to meet your needs makes us your preferred lender.
								</p>
								<p>
									When you contact Yoonimo, your requests and needs will be carefully listened to, and reviewed by industry expert with years of experiences and
									best possible solution will be presented to you.
								</p>
							</Col>
							<div className="button-wrapper mt-4">
								<Button className="primary-button  mr-3 no-deco">
									<Link to="/investors">
										<span className="button-inner-text">View Inventory</span>
									</Link>
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section id="loan-products" className="my-4">
				<Container>
					<Row>
						<Col className=" text-center retina-font">
							<h2>Loan Products</h2>
							<p>
								<span className="badge badge-pill badge-dark">Purchase</span> <span className="badge badge-pill badge-dark">refinance</span>
								<span className="badge badge-pill badge-dark">2nd</span> <span className="badge badge-pill badge-dark">3rd</span>
								<span className="badge badge-pill badge-dark">Bridge/Mezzarnine</span>
								<span className="badge badge-pill badge-dark">Rehab/Fix&Flip (Short & Long terms)</span>
								<span className="badge badge-pill badge-dark">Residential Rental (Short & 30 yr)</span>
								<span className="badge badge-pill badge-dark">Construction</span> <span className="badge badge-pill badge-dark">Land</span>
								<span className="badge badge-pill badge-dark">Agricultural</span>
								<span className="badge badge-pill badge-dark">Project Development Financing</span>
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="diffrent">
				{/* <Container className="mt-120p mb-5"> */}
				<Container>
					<Row>
						<Col className="col-12 col-lg-6 mt-3 mb-5">
							<img alt="man with graph chart illust" className="img-fluid" src="/aboutUsPage/cuate.png"></img>
						</Col>

						<Col className="col-12 col-lg-6 retina-font">
							<h2>How We are Different</h2>
							<BRStick />
							<ul className="mt-4 no-bullet">
								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">The escrow officer oversees closing of the transaction.</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">Escrow and Title insurance is ordered with every transaction.</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">
										If the borrower does get behind, we provide the our own leading foreclosure service to step in and conduct and complete the foreclosure
										process to make it hassle free for the investor(s).
									</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">
										Investor/Beneficiary will receive all documentation pertinent to file (1003 form, credit report, appraisal, preliminary title report, escrow
										instructions, lease agreements, income documents, etc.)
									</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block"> Investor/Beneficiary have the ability to service their own loan.</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block"> We only lend on non-owner occupied and commercial real estate in California, keeping all properties close.</span>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default AboutUsPage;
