import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faFileAlt, faArchive, faUser, faUsers, faUserFriends, faSignOutAlt, faCog, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import useResize from '../../utils/useResize';

import axios from 'axios';
const menus = [
	/* {
		menu: 'dashboard',
		link: '',
		role: 'all',
		icon: <FontAwesomeIcon icon={faTachometerAlt} />,
		group: 'user',
	}, */
	{
		menu: 'refer a friend',
		link: '/referral',
		role: 'all',
		icon: <FontAwesomeIcon icon={faUserFriends} />,
		group: 'user',
	},
	{
		menu: 'my inquiries',
		link: '/my-inquiries',
		role: 'all',
		icon: <FontAwesomeIcon icon={faFileAlt} />,
		group: 'user',
	},
	{
		menu: 'my account',
		link: '/my-account',
		role: 'all',
		icon: <FontAwesomeIcon icon={faUser} />,
		group: 'user',
	},
	{
		menu: 'users',
		link: '/users',
		role: 'admin',
		icon: <FontAwesomeIcon icon={faUsers} />,
		group: 'admin',
	},
	{
		menu: 'inquiries',
		link: '/inquiries',
		role: 'admin',
		icon: <FontAwesomeIcon icon={faArchive} />,
		group: 'admin',
	},
	{
		menu: 'marketing',
		link: '/marketing',
		role: 'admin',
		icon: <FontAwesomeIcon icon={faBullhorn} />,
		group: 'admin',
	},
	{
		menu: 'setting',
		link: '/setting',
		role: 'admin',
		icon: <FontAwesomeIcon icon={faCog} />,
		group: 'admin',
	},

	{
		menu: 'logout',
		role: 'all',
		icon: <FontAwesomeIcon icon={faSignOutAlt} />,
		group: 'user',
	},
];

const Sidebar = props => {
	const { currentUserData, currentUrl, toggled, toggleHandler } = props;

	const logoutHandler = () => {
		axios.get(`/api/users/logout`).then(response => {
			if (response.data.success) {
				props.history.push('/login');
			} else {
				alert('Log Out Failed');
			}
		});
	};

	if (currentUserData?.isAuth === true) {
		return (
			<section id="dashboard-sidebar" className={`${toggled === 'toggled' ? 'collapsed' : 'show d-flex '}`}>
				<Container className="bg-white">
					<Row>
						<Col className="p-0">
							<div className="logo">
								<Link className="no-deco" to="/">
									<img alt="Yoonimo logo" src="/logos/yoonimo_600x102.png" width="auto" height="39px" />
								</Link>
							</div>
							<ul className="no-bullet ">
								{menus.map((menu, i) => {
									if (menu.link !== null && menu.link !== undefined && (menu.role === 'all' || menu.role === currentUserData.role)) {
										return (
											<Link className="no-deco" key={i} to={`/dashboard${menu.link}`}>
												<li className={`/dashboard${menu.link}` === currentUrl ? `current` : ``}>
													<div className="align-middle">{menu.icon}</div>
													<span className="align-middle">{menu.menu}</span>
												</li>
											</Link>
										);
									} else if (menu.menu === 'logout') {
										return (
											<li key={i} className={`/dashboard${menu.link}` === currentUrl ? `current` : ``}>
												<div onClick={logoutHandler}>
													<div className="align-middle">{menu.icon}</div>
													<span className="align-middle">{menu.menu}</span>
												</div>
											</li>
										);
									} else {
										return null;
									}
								})}
							</ul>
						</Col>
					</Row>
				</Container>
				<Container id="sidebar-outer" onClick={toggleHandler}></Container>
			</section>
		);
	} else {
		return null;
	}
};

export default Sidebar;
