import React, { useEffect } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../components/kr/global/Layout';

const KRCompletionPage = props => {
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<section id="welcome-page">
				<Card className="card-border-disabled">
					<Card.Body>
						<Link to="/kr/">
							<a>
								<Card.Img variant="top" className="form-logo" src="/logos/yoonimo_logo.png" />
							</a>
						</Link>
						<Card.Text className="welcome-text mt-4">
							Thank you for Submitting your application!
							<br />
							<br />
							Please allow up to 24 business hours for us to review and verify.
							<br />
							We might contact you to gather more information if required.
							<br />
							Your inquiry will be listed on the Investors page after the verification.
							<br />
							<br />
							If you have any questions or concerns please contact us at :<br />
							<br />
							<span className="service-email">john@yoonimo.com</span>
							<br />
							<br />
						</Card.Text>
						<Row className="justify-content-center">
							<Col xs={6}>
								<Link to="/kr/" className="no-deco">
									<a>
										<Button className="primary-button" size="lg" block>
											Go back to Home
										</Button>
									</a>
								</Link>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</section>
		</Layout>
	);
};

export default KRCompletionPage;
