import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/kr/global/Layout';

// import downArrow from './../../components/HowItWorks/downArrow.png';
// import downArrow from './../../public/HowItWorks/downArrow.png';
// import downMobile from './../../components/HowItWorks/downMobile.png';
import { CenterStick, OnelineStick } from '../../components/global/svgs';
import { Helmet } from 'react-helmet';
const KRHowItWorks = props => {
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">How it Works - Yoonimo</title>
				<meta name="description" content="" />
				<link rel="canonical" data-react-helmet="true" href="https://yoonimo.com/kr/how-it-works" />
				<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="How it Works - Yoonimo" />
				<meta property="og:description" content="" />
				<meta data-react-helmet="true" property="og:url" content="https://yoonimo.com/kr/how-it-works" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
			</Helmet>
			<section className="howItWorks" id="how-it-works">
				<div className="bg-illust-wrapper">
					<figure className="howItWorks-BG" />
				</div>
				<Container>
					<Row>
						<Col>
							<h2 className="main-title mt-71p">How it works</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="arrow-box mt-3">
								<CenterStick />
								<img className="downArrow mt-3" src="/HowItWorks/downArrow.png" alt="" />
							</div>
						</Col>
					</Row>
					<ol className="mobile-chart-margin">
						{/* 1st row */}
						<li>
							<Row className="list-row">
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step1.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">융자 신청자는 웹사이트를 통해 융자 신청서를 제출합니다.</h3>
										<OnelineStick />
									</div>
								</Col>
								<Col className="row-space row-space-odd">
									<div className="img-box">
										<img className="arrow-img arrow-img-odd " src="/HowItWorks/rightDown.png" alt="pointing bottom right" />
									</div>
								</Col>
							</Row>
						</li>
						{/* 2n row */}
						<li>
							<Row className="list-row">
								<Col className="row-space row-space-even">
									<img className="arrow-img arrow-img-even" src="/HowItWorks/leftDown.png" alt="pointing bottom left" />
								</Col>
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step2.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">Yoonimo는 검증 및 추가 정보를 위해 융자 신청자에게 연락합니다.</h3>
										<OnelineStick />
									</div>
								</Col>
							</Row>
						</li>
						{/* 3st row */}
						<li>
							<Row className="list-row">
								<div className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step3.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">신청된 융자는 yoonimo.com으로 올라갑니다.</h3>
										<OnelineStick />
									</div>
								</div>
								<div className="row-space row-space-odd">
									<div className="img-box">
										<img className="arrow-img arrow-img-odd " src="/HowItWorks/rightDown.png" alt="pointing bottom right" />
									</div>
								</div>
							</Row>
						</li>
						{/* 4n row */}
						<li>
							<Row className="list-row">
								<Col className="row-space row-space-even">
									<img className="arrow-img arrow-img-even" src="/HowItWorks/leftDown.png" alt="pointing bottom left" />
								</Col>
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step4.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">우리는 투자자들을 연결하고 실사를 처리합니다.</h3>
										<OnelineStick />
									</div>
								</Col>
							</Row>
						</li>{' '}
						{/* 5th row */}
						<li>
							<Row className="list-row">
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step5.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">대출이 승인되면 에스크로는 융자금을 융자 신청자에게 풀어줍니다.</h3>
										<OnelineStick />
									</div>
								</Col>
								<Col className="row-space row-space-odd">
									<div className="img-box">
										<img className="arrow-img arrow-img-odd " src="/HowItWorks/rightDown.png" alt="pointing bottom right" />
									</div>
								</Col>
							</Row>
						</li>
						{/* 6th row */}
						<li>
							<Row className="list-row">
								<Col className="row-space row-space-even"></Col>
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step6.png" alt="first step" />
									</div>
									<div className="step-info">
										<h3 className="title">융자 대출 완료!!!</h3>
										<OnelineStick />
									</div>
								</Col>
							</Row>
						</li>
					</ol>
				</Container>
			</section>
		</Layout>
	);
};

export default KRHowItWorks;
