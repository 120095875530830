import React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col } from 'react-bootstrap';
import { CenterStick, BRStick } from '../../components/global/svgs';
import { Link } from 'react-router-dom';
import { Arrowhead } from '../../components/global/svgs';
import Layout from '../../components/kr/global/Layout';
import BlogCards from '../../components/Blog/BlogCards';
import axios from 'axios';

const KRBlogPage = props => {
	const currentUserData = props.user.data;
	const [posts, setPosts] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		axios.get('https://cozmous.com/wp-json/wp/v2/posts?_embed').then(res => {
			/* console.log(res); */
			if (res.status === 200) {
				setPosts(res.data);
			} else {
				console.log('Error response code: ', res.status);
			}
		});
	}, []);

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Blog - Yoonimo</title>
				<link data-react-helmet="true" rel="canonical" href="https://yoonimo.com/kr/blog/" />
				<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="Blog - Yoonimo" />
				<meta
					data-react-helmet="true"
					property="og:description"
					content="The mission of Yoonimo is to provide investors and beneficiaries with the best possible trust deed investment opportunities. We can bring you the best deals in high return investment funds, commercial real estate loans, and investment properties in California."
				/>
				<meta data-react-helmet="true" property="og:url" content="/kr/blog/" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
				<meta data-react-helmet="true" property="og:image" content="/about/ezgif.com-webp-to-jpg.jpg" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
			</Helmet>
			<div className="bg-illust-wrapper">
				<figure className="AboutUs-top-spill" />
				<figure className="AboutUs-bottom-spill" />
			</div>
			<section id="blog">
				<Container>
					<Row className="mt-71p">
						<BlogCards posts={posts} />
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default KRBlogPage;
