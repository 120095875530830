import React, { useEffect, useState, useRef } from 'react';
import Contact from '../../components/kr/Home/Contact';
import Hero from '../../components/kr/Home/Hero';
import Ribbon from '../../components/kr/Home/Ribbon';
import HeroV2 from '../../components/kr/Home/HeroV2';
import AboutUs from '../../components/kr/Home/AboutUs';
import Invest from '../../components/kr/Home/Invest';
import FAQ from '../../components/kr/Home/FAQ';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Layout from '../../components/kr/global/Layout';
import Footer from '../../components/kr/global/Footer';

const KRHomePage = props => {
	const currentUserData = props.user.data;
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [activeAccordion, setActiveAccordion] = useState(0);
	const [properties, setProperties] = useState([[]]);

	const jumpToSectionRef = useRef();
	const contactRef = useRef();

	const activateAccordion = target => {
		if (activeAccordion === target) {
			setActiveAccordion(0);
		} else {
			setActiveAccordion(target);
		}
	};

	const getProperties = () => {
		const variables = {
			limit: 12,
			page: 'home',
			requested: 'false',
		};
		axios.get('/api/inquiry/get/properties', { params: variables }).then(res => {
			if (res.data.success) {
				const tmpArr = res.data.currentProperties;
				let tmpDoubleArray = [[], [], []];
				let pages = 0;

				for (let propCount = 0; propCount < 12; propCount++) {
					if (propCount === 4 || propCount === 8) {
						pages++;
					}
					tmpDoubleArray[pages].push(tmpArr[propCount]);
				}
				setProperties(tmpDoubleArray);
			} else {
				alert('Failed to fetch property datas');
			}
		});
	};

	useEffect(getProperties, []);

	useEffect(props => {
		if (window.location.hash === '#home-contact-us' || window.location.hash === '#contact-us') {
			contactRef.current.scrollIntoView();
		}
	}, []);

	return (
		<>
			<Layout currentUserData={currentUserData} history={props.history}>
				<Helmet>
					<meta data-react-helmet="true" charSet="utf-8" />
					<title data-react-helmet="true">Home - Yoonimo</title>
					<meta
						data-react-helmet="true"
						name="description"
						content="At Yoonimo, we provide you with high return investment funds, commercial real estate loans and investment properties. We are committed to delivering a high level of expertise, customer service, and attention to detail in hard money lending."
					/>
					<link data-react-helmet="true" rel="canonical" href="https://yoonimo.com/kr/" />
					<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
					<meta data-react-helmet="true" property="og:type" content="website" />
					<meta data-react-helmet="true" property="og:title" content="Home - Yoonimo" />
					<meta
						data-react-helmet="true"
						property="og:description"
						content="At Yoonimo, we provide you with high return investment funds, commercial real estate loans and investment properties. We are committed to delivering a high level of expertise, customer service, and attention to detail in hard money lending."
					/>
					<meta data-react-helmet="true" property="og:url" content="https://yoonimo.com/kr/" />
					<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
					<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
				</Helmet>
				<main>
					<Ribbon />
					<HeroV2 jumpToSectionRef={jumpToSectionRef} />
					<AboutUs jumpToSectionRef={jumpToSectionRef} />
					<Invest properties={properties} />
					<FAQ activeAccordion={activeAccordion} activateAccordion={activateAccordion} />
					<Contact contactRef={contactRef} />
				</main>
				<Footer contactRef={contactRef} />
			</Layout>
		</>
	);
};

export default KRHomePage;
