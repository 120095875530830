import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import Spinner from '../../../components/Spinner';
import { getComparator, stableSort, useToolbarStyles } from '../../../components/board/utils';
import { Helmet } from 'react-helmet';
import { priceTag, calculateLTV, showWhichTD } from '../../../components/utils/functions';

import DashboardLayout from '../../../components/dashboard/DashboardLayout';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';

import { Editor } from '@tinymce/tinymce-react';
// import { Code } from './Marketing-tinyMce';
const inquiryStatuses = ['all', 'pending', 'verified', 'completed'];

const headCells = [
	{ id: 'loanType', align: 'left', disablePadding: false, label: 'Loan Type' },
	{
		id: 'address',
		align: 'left',
		disablePadding: false,
		label: 'Address',
	},
	{
		id: 'loanAmt',
		align: 'left',
		disablePadding: false,
		label: 'Req. Loan Amt',
	},
	// {
	// 	id: 'term',
	// 	align: 'left',
	// 	disablePadding: false,
	// 	label: 'Term',
	// },
	// {
	// 	id: 'interestRate',
	// 	align: 'left',
	// 	disablePadding: false,
	// 	label: 'Interest (%)',
	// },
	// {
	// 	id: 'status',
	// 	align: 'left',
	// 	disablePadding: false,
	// 	label: 'Status',
	// },
];

const MarketingPageHead = props => {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onInquiriesort } = props;
	const createSortHandler = property => event => {
		onInquiriesort(event, property);
	};
	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox" className="checkbox-white">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts',
						}}
					/>
				</TableCell>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
							<span className="white-font-color ellipsis">{headCell.label}</span>
							{orderBy === headCell.id ? <span className="visuallyHidden">{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const InquiriesToolbar = props => {
	const classes = useToolbarStyles();
	const { numSelected, handleDelete, fetch } = props;

	return (
		<Toolbar
			className={`${clsx({
				[classes.highlight]: numSelected > 0,
			})} + toolbar`}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<span className="d-block font-weight-500 w-100">All Inquiries</span>
				// <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
				// 	All Inquiries
				// </Typography>
			)}

			{inquiryStatuses.map(inquiryStatus => (
				<Button onClick={e => fetch(inquiryStatus)} key={inquiryStatus} className="primary-button mr-1 p-2">
					{inquiryStatus}
				</Button>
			))}

			<Tooltip title="Add new request">
				<Link to="/dashboard/admin/create/inquiry">
					<IconButton aria-label="Add new request">
						<PostAddIcon />
					</IconButton>
				</Link>
			</Tooltip>

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton aria-label="delete">
						<DeleteIcon onClick={handleDelete} />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton aria-label="filter list">
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

const KRMarketingPage = props => {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('calories');
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [dense, setDense] = useState(true);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const currentUserData = props.user.data;
	const [rows, setRows] = useState([]);

	const fetch = async inquiryStatus => {
		return await axios.get(`/api/inquiry/get/admin/properties/${inquiryStatus}`).then(res => {
			if (res.data.success) {
				setRows(
					res.data.properties
						.map((property, i) => ({
							address: `${property.subjectPropertyAddr.line1} ${property.subjectPropertyAddr.line2} ${property.subjectPropertyAddr.city}`,
							loanType: property.loanType,
							loanAmt: property.loanAmt,
							term: property.term,
							interestRate: property.interestRate,
							images: property.images,
							status: property.status,
							id: property._id,
							currentEstValue: property.currentEstValue,
							typeOfProperty: property.typeOfProperty,
							mortgageBalance: property.mortgageBalance,
							purchasedPrice: property.purchasedPrice,
							downPayment: property.downPayment,
							// purchasedPrice: property.purchaseLoan.purchasedPrice,
							LTV: `${calculateLTV(property) > 100 || isNaN(calculateLTV(property)) || calculateLTV(property) === 0 ? '-' : calculateLTV(property).toFixed(2)}%`,
						}))
						.reverse()
				);
				// setRows(
				// 	res.data.properties.map((property, i) => ({
				// 		address: `${property.property.streetAddress} ${property.property.streetAddress2nd} ${property.property.city} ${property.property.state} ${property.property.zip}`,
				// 		reqLoanAmount: property.reqLoanAmount,
				// 		term: property.term,
				// 		interestRate: property.interestRate,
				// 		status: property.status,
				// 		id: property._id,
				// 	}))
				// );
			} else {
				alert('Failed to fetch property datas');
			}
		});
	};

	useEffect(() => {
		if (currentUserData && currentUserData._id) {
			fetch('all');
		}
	}, [currentUserData]);

	const handleInquiriesort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleDelete = e => {
		e.preventDefault();
		const list = selected.map(index => {
			return rows[index].id;
		});
		const queryObj = { data: { list } };
		axios.delete('/api/inquiry/delete/properties/', queryObj).then(response => {
			if (response.data.success) {
				window.location.reload();
			} else {
				alert('Failed to delete property');
			}
		});
	};

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n, index) => index);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, index, id) => {
		const selectedIndex = selected.indexOf(index);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, index);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}
		setSelected(newSelected);
	};

	const handleRowClick = (e, row) => {
		e.preventDefault();
		props.history.push(`/dashboard/admin/edit/inquiry/${row.id}`);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = event => {
		setDense(event.target.checked);
	};

	const isSelected = index => selected.indexOf(index) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
	//////////////
	//////////////
	//////////////
	//////////////
	//////////////
	//////////////
	//////////////
	//////////////
	//////////////
	//////////////
	//////////////
	//////////////
	const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('');

	const displayFirstProperty = firstProperty => {
		switch (firstProperty) {
			case 'refinance':
				/* console.log('refinance'); */
				return `Loan Type: ${capitalize(rows[selected[0]].loanType)}<br>
                Approx. Appraisal Value: ${priceTag(rows[selected[0]].currentEstValue)}<br>
                Req. Loan Amount: ${priceTag(rows[selected[0]].loanAmt)}<br>
                Interest: ${rows[selected[0]].interestRate}%<br><br>

                Lien Position: ${showWhichTD(rows[selected[0]].mortgageBalance.index)} <br> 
                Property Type: ${capitalize(rows[selected[0]].typeOfProperty)} <br>
                LTV: ${rows[selected[0]].LTV} <br>`;

			case 'purchase':
				return `Loan Type: ${capitalize(rows[selected[0]].loanType)}<br>
                Req. Loan Amount: ${priceTag(rows[selected[0]].loanAmt)}<br>
                Purchase Price: ${priceTag(rows[selected[0]].purchasedPrice)}<br>
                Down Payment: ${priceTag(rows[selected[0]].downPayment)}<br><br>

                Lien Position: ${showWhichTD(rows[selected[0]].mortgageBalance.index)} <br> 
                Property Type: ${capitalize(rows[selected[0]].typeOfProperty)} <br>
                LTV: ${rows[selected[0]].LTV} <br>`;

			case 'construction/project':
				return `Loan Type: ${capitalize(rows[selected[0]].loanType)}<br>
                Approx. Appraisal Value: ${priceTag(rows[selected[0]].currentEstValue)}<br>
                Req. Loan Amount: ${priceTag(rows[selected[0]].loanAmt)}<br>
                Lien Position: ${showWhichTD(rows[selected[0]].mortgageBalance.index)} <br> 
               
                LTV: ${rows[selected[0]].LTV} <br>`;

			case 'fix/flip':
				return `Loan Type: ${capitalize(rows[selected[0]].loanType)}<br>
                Approx. Appraisal Value: ${priceTag(rows[selected[0]].currentEstValue)}<br>
                Req. Loan Amount: ${priceTag(rows[selected[0]].loanAmt)}<br>
                Interest: ${rows[selected[0]].interestRate}%<br><br>

                Lien Position: ${showWhichTD(rows[selected[0]].mortgageBalance.index)} <br> 
                Property Type: ${capitalize(rows[selected[0]].typeOfProperty)} <br>
                LTV: ${rows[selected[0]].LTV} <br>`;
			case 'fix & flip/Rehab':
				return `Approx. Appraisal Value: $<br>
                                  Req. Loan Amount: ${priceTag(rows[selected[0]].loanAmt)}<br>
                                  LTV: %<br><br>
                                  Building Size: Sq.Ft.<br>
                                  Lot Size:  Sq.Ft.<br>`;

			default:
				break;
		}
	};

	const [htmlCode, setHtmlCode] = useState('');
	const createCode = async () => {
		setHtmlCode('');
		if (selected.length === 5) {
			// console.log(` 0:  ${priceTag(rows[selected[0]].purchaseLoan)}`);
			// console.log(rows[selected[1]].loanType);
			// console.log(rows[selected[2]].loanType);
			// console.log(rows[selected[3]].loanType);
			// console.log(rows[selected[4]].loanType);

			// console.log(`selected length: ${selected.length}`);

			// midCode = midCode + `</table></td>`;
			// console.log(midCode);
			await setHtmlCode(`<!doctype html>
<html>

<head>
  <meta http-equiv="content-type" content="text/html; charset=utf-8" />
  <!--[if (gte mso 9)|(IE)]><xml>96</xml><![endif]-->
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap"
    rel="stylesheet">
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <!-- So that mobile will display zoomed in -->
  <meta http-equiv="x-ua-compatible" content="IE=edge" />
  <!-- enable media queries for windows phone 8 -->
  <meta name="format-detection" content="telephone=no" />
  <!-- disable auto telephone linking in iOS -->
  <meta name="format-detection" content="date=no" />
  <!-- disable auto date linking in iOS -->
  <meta name="format-detection" content="address=no" />
  <!-- disable auto address linking in iOS -->
  <meta name="format-detection" content="email=no" />

  <style type="text/css" data-premailer="ignore">
    .ReadMsgBody {
      width: 100%;
      background-color: #d2d2d2;
    }

    .ExternalClass {
      width: 100%;
      background-color: #d2d2d2;
    }

    .ExternalClass,
    .ExternalClass p,
    .ExternalClass span,
    .ExternalClass font,
    .ExternalClass td {
      line-height: 100%;
      mso-line-height-rule: exactly;
    }

    html {
      width: 100%;
      font-family: 'Nunito Sans', arial, sans-serif !important;
      mso-line-height-rule: exactly !important;
    }

    body {
      -webkit-text-size-adjust: none;
      -ms-text-size-adjust: none;
      margin: 0;
      padding: 0;
      background: #d2d2d2;
      font-family: 'Nunito Sans', arial, sans-serif !important;
    }

    table {
      border-spacing: 0;
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
    }

    table td {
      border-collapse: collapse;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      mso-line-height-rule: exactly;
    }

    img {
      display: block !important;
      border: 0 !important;
      height: auto !important;
      outline: none !important;
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }

    a {
      text-decoration: none;
      color: inherit !important;
    }

    /* Desktop View */
    .container {
      width: 650px;
    }

    .sub-container {
      width: 610px;
    }

    .listing-block-1 {
      width: 295px;
    }

    .listing-block-2 {
      width: 20px;
    }

    .image-fluid img {
      width: 100%;
      height: auto;
    }

    @media only screen and (max-width: 599px) {
      .container {
        max-width: 650px !important;
        width: 95% !important;
      }

      .sub-container {
        width: 610px !important;
        width: 90% !important;
      }

      .listing-block-1 {
        width: 295px;
        width: 100% !important;
        display: block !important;
      }

      .listing-block-2 {
        width: 20px;
        width: 100% !important;
        display: block !important;
        height: 20px !important;
      }

      .image-fluid img {
        width: 100%;
        height: auto;
      }

      .m-f-image img {
        width: 100%;
        height: auto;
      }

      .m-font-25 {
        font-size: 25px !important;
      }
    }
  </style>
  <!--[if mso]>
<style type="text/css">
body, table, td,a{font-family: Arial, Helvetica, sans-serif !important;}
</style>
<![endif]-->
</head>

<body style="margin:0px; padding:0px; background:#d2d2d2;">[[trackingImage]]
  <table cellpadding="0" cellspacing="0" border="0" align="center" width="100%" bgcolor="#d2d2d2"
    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; background:#d2d2d2">
    <tr>
      <td valign="top" align="center">
        <table cellpadding="0" cellspacing="0" border="0" align="center" width="600" class="container" bgcolor="#ffffff"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
          <tr>
            <td valign="top" align="center">
              <table cellpadding="0" cellspacing="0" border="0" align="center" width="610" class="sub-container"
                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                <tr>
                  <td valign="top" height="35"></td>
                </tr>
                <tr>
                  <td valign="top" align="center" class="m-f-image">
                    <a href="https://yoonimo.com/" target="_blank"><img
                        src="https://yoonimo.com/email/logo.png" alt></a>
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="35"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td valign="top" align="center" class="image-fluid">
              <img src="https://yoonimo.com/email/top-divider.jpg" alt>
            </td>
          </tr>
          <tr>
            <td valign="top" bgcolor="#b42025" height="25"></td>
          </tr>
          <tr>
            <td valign="top" align="center" bgcolor="#b42025"
              style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#ffffff; font-size:38px; font-weight:bold; font-style:italic;"
              class="m-font-25">
              Listing of the Day!
            </td>
          </tr>
          <tr>
            <td valign="top" bgcolor="#b42025" height="25"></td>
          </tr>
          <!-- -------------------------------------- ↑↑↑ DO NOT CHANGE ABOVE THIS LINE ↑↑↑ -------------------------------------- -->
          <!-- -------------------------------------- ↑↑↑ DO NOT CHANGE ABOVE THIS LINE ↑↑↑ -------------------------------------- -->
          <!-- -------------------------------------- ↑↑↑ DO NOT CHANGE ABOVE THIS LINE ↑↑↑ -------------------------------------- -->



          <tr>
            <td valign="top" align="center" class="image-fluid">
              <!--==============================- CHANGE BELOW ==============================-->
              <img src="https://yoonimo.com/${rows[selected[0]].images[0]}"
              
                alt="Listing property">
            </td>
          </tr>
          <tr>
            <td valign="top" bgcolor="#b42025" align="center">
              <table cellpadding="0" cellspacing="0" border="0" align="center" width="610" bgcolor="#b42025"
                class="sub-container" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                <tr>
                  <td valign="top" height="30"></td>
                </tr>
                <tr>
                  <td valign="top" align="center"
                    style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#ffffff; font-size:24px; font-weight:bold;">
                    ${rows[selected[0]].address}
                    </td>
                    </tr>
                    <tr>
                    <td valign="top" height="15"></td>
                    </tr>
                    <tr>
                    <td valign="top" align="center"
                    style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#ffffff; font-size:16px; font-weight:normal; line-height:150%">
                    <!--==============================- CHANGE BELOW ==============================-->
                    ${displayFirstProperty(rows[selected[0]].loanType)}
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="25"></td>
                </tr>
                <tr>
                  <td valign="top" align="center">
                    <table cellpadding="0" cellspacing="0" border="0" align="center" width="122" bgcolor="#ffffff"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                      <tr>
                        <td valign="top" height="10"></td>
                      </tr>
                      <tr>
                        <td valign="top" align="center"
                          style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#461451; font-size:17px; font-weight:bold; text-transform:uppercase;">
                          <!--==============================- CHANGE BELOW ==============================-->
                          <a href="https://yoonimo.com/property/${rows[selected[0]].id}" target="_blank"
                            style="color:#461451; text-decoration:none;">Visit Now</a>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" height="10"></td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="20"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td valign="top" class="image-fluid">
              <img src="https://yoonimo.com/email/listing-content-divider.jpg" alt>
            </td>
          </tr>
          <tr>
            <td valign="top" align="center" bgcolor="#ffffff">
              <table cellpadding="0" cellspacing="0" border="0" align="center" width="610" bgcolor="#ffffff"
                class="sub-container" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                <tr>
                  <td valign="top" height="30"></td>
                </tr>
                <tr>
                  <td valign="top" align="center"
                    style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#461451; font-size:26px; font-weight:bold;">
                    View All Current Listings!
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="25"></td>
                </tr>
                <tr>
                  <td valign="top" align="center">
                    <table cellpadding="0" cellspacing="0" border="0" align="center" width="100%"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                      <tr>
                        <td valign="top" class="listing-block-1">
                          <table cellpadding="0" cellspacing="0" border="0" align="center" width="100%"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                            <tr>
                              <td valign="top" align="center" class="image-fluid">
                                <!--==============================- CHANGE BELOW ==============================-->
                                <img style="width: 295px; height: 200px !important; object-fit: cover !important;" 
                                src="https://yoonimo.com/${rows[selected[1]].images[0]}"
                                  alt="Listing 1">
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="15"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center"
                                style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#2c2c2c; font-size:17px; font-weight:600;">
                                <!--==============================- CHANGE BELOW ==============================-->
                                Borrower requests ${priceTag(rows[selected[1]].loanAmt)} with desired interest rate of
                                ${rows[selected[1]].interestRate}%.
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="25"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center">
                                <table cellpadding="0" cellspacing="0" border="0" align="center" width="110"
                                  bgcolor="#b42025"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                                  <tr>
                                    <td valign="top" height="6"></td>
                                  </tr>
                                  <tr>
                                    <td valign="top" align="center"
                                      style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#ffffff; font-size:16px; font-weight:normal; text-transform:uppercase;">
                                      <!--==============================- CHANGE BELOW ==============================-->
                                      <a href="https://yoonimo.com/property/${rows[selected[1]].id}"
                                        target="_blank" style="color:#ffffff; text-decoration:none;">Visit Now</a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td valign="top" height="6"></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="15"></td>
                            </tr>
                          </table>
                        </td>
                        <td valign="top" class="listing-block-2"></td>
                        <td valign="top" class="listing-block-1">
                          <table cellpadding="0" cellspacing="0" border="0" align="center" width="100%"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                            <tr>
                              <td valign="top" align="center" class="image-fluid">
                                <!--==============================- CHANGE BELOW ==============================-->

                               <img style="width: 295px; height: 200px !important; object-fit: cover !important;" 
                               src="https://yoonimo.com/${rows[selected[2]].images[0]}"
                                  alt="Listing 1">
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="15"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center"
                                style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#2c2c2c; font-size:17px; font-weight:600;">
                                <!--==============================- CHANGE BELOW ==============================-->
                                Borrower requests ${priceTag(rows[selected[2]].loanAmt)} with desired interest rate of
                                ${rows[selected[2]].interestRate}%.
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="25"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center">
                                <table cellpadding="0" cellspacing="0" border="0" align="center" width="110"
                                  bgcolor="#b42025"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                                  <tr>
                                    <td valign="top" height="6"></td>
                                  </tr>
                                  <tr>
                                    <td valign="top" align="center"
                                      style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#ffffff; font-size:16px; font-weight:normal; text-transform:uppercase;">
                                      <!--==============================- CHANGE BELOW ==============================-->
                                      <a href="https://yoonimo.com/property/${rows[selected[2]].id}"
                                        target="_blank" style="color:#ffffff; text-decoration:none;">Visit Now</a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td valign="top" height="6"></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="15"></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="15"></td>
                </tr>
                <tr>
                  <td valign="top" align="center">
                    <table cellpadding="0" cellspacing="0" border="0" align="center" width="100%"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                      <tr>
                        <td valign="top" class="listing-block-1">
                          <table cellpadding="0" cellspacing="0" border="0" align="center" width="100%"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                            <tr>
                              <td valign="top" align="center" class="image-fluid">
                                <!--==============================- CHANGE BELOW ==============================-->
                                <img style="width: 295px; height: 200px !important; object-fit: cover !important;"
                                src="https://yoonimo.com/${rows[selected[3]].images[0]}"
                                  alt="Listing 1">
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="15"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center"
                                style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#2c2c2c; font-size:17px; font-weight:600;">
                                <!--==============================- CHANGE BELOW ==============================-->

                                Borrower requests ${priceTag(rows[selected[3]].loanAmt)} with desired interest rate of
                                ${rows[selected[3]].interestRate}%.
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="25"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center">
                                <table cellpadding="0" cellspacing="0" border="0" align="center" width="110"
                                  bgcolor="#b42025"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                                  <tr>
                                    <td valign="top" height="6"></td>
                                  </tr>
                                  <tr>
                                    <td valign="top" align="center"
                                      style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#ffffff; font-size:16px; font-weight:normal; text-transform:uppercase;">
                                      <!--==============================- CHANGE BELOW ==============================-->
                                      <a href="https://yoonimo.com/property/${rows[selected[3]].id}"
                                        target="_blank" style="color:#ffffff; text-decoration:none;">Visit Now</a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td valign="top" height="6"></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="15"></td>
                            </tr>
                          </table>
                        </td>
                        <td valign="top" class="listing-block-2"></td>
                        <td valign="top" class="listing-block-1">
                          <table cellpadding="0" cellspacing="0" border="0" align="center" width="100%"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                            <tr>
                              <td valign="top" align="center" class="image-fluid">
                                <!--==============================- CHANGE BELOW ==============================-->
                                <img style="width: 295px; height: 200px !important; object-fit: cover !important;" 
                                src="https://yoonimo.com/${rows[selected[4]].images[0]}"
                                  alt="Listing 1">
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="15"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center"
                                style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#2c2c2c; font-size:17px; font-weight:600;">
                                <!--==============================- CHANGE BELOW ==============================-->
                                Borrower requests ${priceTag(rows[selected[4]].loanAmt)} with desired interest rate of
                                ${rows[selected[4]].interestRate}%.
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" height="25"></td>
                            </tr>
                            <tr>
                              <td valign="top" align="center">
                                <table cellpadding="0" cellspacing="0" border="0" align="center" width="110"
                                  bgcolor="#b42025"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                                  <tr>
                                    <td valign="top" height="6"></td>
                                  </tr>
                                  <tr>
                                    <td valign="top" align="center"
                                      style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#ffffff; font-size:16px; font-weight:normal; text-transform:uppercase;">
                                      <a href="https://yoonimo.com/property/${rows[selected[4]].id}" 
                                      target="_blank" style="color:#ffffff; text-decoration:none;">Visit Now</a>
                                    </td>
                                    <!-- -------------------------------------- ↓↓↓ DO NOT CHANGE BELOW THIS LINE ↓↓↓ -------------------------------------- -->
                                    <!-- -------------------------------------- ↓↓↓ DO NOT CHANGE BELOW THIS LINE ↓↓↓ -------------------------------------- -->
                                    <!-- -------------------------------------- ↓↓↓ DO NOT CHANGE BELOW THIS LINE ↓↓↓ -------------------------------------- -->
                                  </tr>
                                  <tr>
                                    <td valign="top" height="6"></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            <tr>
                              <td valign="top" height="15"></td>
                            </tr>
                          </table>

                        </td>
                      </tr>
                    </table>
                    <table cellpadding="0" cellspacing="0" border="0" align="center"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                      <tr>
                        <td valign="top" height="25"></td>
                      </tr>
                      <tr>
                        <td align="center"
                          style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#2c2c2c; font-size:30px; font-weight:bold;">


                          Borrower Request. Invest with US <br>

                        </td>
                      </tr>
                      <tr>
                        <td align="center"
                          style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#2c2c2c; font-size:13px; font-weight:bold;">

                          Commercial, Multi(5+), Residential, Construction, Land, Cannabis, Environmental<br>

                          ( Fix & Flip, Purchase, Refi/Cash Out, 2nd & 3rd, JV )<br>
                        </td>
                      </tr>
                      <tr>
                        <td align="center"
                          style="font-family:'Nunito Sans', arial, sans-serif; text-align:center; color:#2c2c2c; font-size:25px; font-weight:bold;">


                          Quick Response! Follow Up! Closing!<br>
                          Have a Question? Contact Us.<br>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" height="25"></td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="15"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td valign="top" bgcolor="#0f0a0a">
              <table cellpadding="0" cellspacing="0" border="0" align="center" width="610" bgcolor="#0f0a0a"
                class="sub-container" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse:collapse;">
                <tr>
                  <td valign="top" height="30"></td>
                </tr>
                <tr>
                  <td valign="top" align="center" class="m-f-image">
                    <a href="https://yoonimo.com/" target="_blank"><img
                        src="https://yoonimo.com/email/footer-logo.png" alt></a>
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="1" bgcolor="#1d1818" style="height:1px;"></td>
                </tr>
                <tr>
                  <td valign="top" height="30"></td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    <table width="200px" cellpadding="0" cellspacing="0" border="0" align="center">
                      <tr>
                        <td><img src="https://yoonimo.com/email/web-link.png" alt> </td>
                        <td
                          style="font-family:'Nunito Sans', arial, sans-serif; color:#ffffff; font-size:13px; font-weight:normal;">
                          <a href="https://yoonimo.com/" target="_blank"
                            style="color:#ffffff; text-decoration:none;">www.yoonimo.com</a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="15"></td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    <table width="200px" cellpadding="0" cellspacing="0" border="0" align="center">
                      <tr>
                        <td><img src="https://yoonimo.com/email/email-icon.png" alt>
                        </td>
                        <td
                          style="font-family:'Nunito Sans', arial, sans-serif; color:#ffffff; font-size:13px; font-weight:normal;">
                          <a href="mailto:loans@yoonimo.com"
                            style="color:#ffffff; text-decoration:none;">loans@yoonimo.com</a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="15"></td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    <table width="150px" cellpadding="0" cellspacing="0" border="0" align="center">
                      <tr>
                        <td><img src="https://yoonimo.com/email/phone-icon.png" alt>
                        </td>
                        <td
                          style="font-family:'Nunito Sans', arial, sans-serif; color:#ffffff; font-size:13px; font-weight:normal;">
                          <a href="tel:818-860-1711" style="color:#ffffff; text-decoration:none;">(818) 860 -
                            1711</a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="25"></td>
                </tr>
                <tr>
                  <td valign="top"
                    style="font-family:'Nunito Sans', arial, sans-serif; color:#ffffff; font-size:13px; font-weight:normal; text-align:center;">
                    Want to change how you receive these emails?<br />
                    You can <a href="#" style="color:#ffffff; text-decoration:underline;">update
                      your profile</a> or <a href="#" style="color:#ffffff; text-decoration:underline;">unsubscribe</a>
                    from this
                    list.
                  </td>
                </tr>
                <tr>
                  <td valign="top" height="30"></td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>

</html>`);
		} else {
			// alert
			alert('select 5 properties');
		}
	};

	const copyCodeToClipboard = async () => {
		await navigator.clipboard.writeText(htmlCode);
		alert('copied to clipboard!');
	};

	if (currentUserData === null) {
		return <Spinner />;
	} else if (currentUserData && currentUserData.isAuth && currentUserData.role !== 'admin') {
		return <Redirect to="/"></Redirect>;
	} else {
		return (
			<DashboardLayout currentUserData={currentUserData} history={props.history}>
				<Helmet>
					<meta data-react-helmet="true" charSet="utf-8" />
					<title data-react-helmet="true">Marketing - Yoonimo</title>
					<meta data-react-helmet="true" name="description" content="" />
					<link rel="canonical" data-react-helmet="true" href="https://yoonimo.com/dashboard/marketing" />
					<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
					<meta data-react-helmet="true" property="og:type" content="article" />
					<meta data-react-helmet="true" property="og:title" content="My Inquiries - Yoonimo" />
					<meta data-react-helmet="true" property="og:description" content="" />
					<meta data-react-helmet="true" property="og:url" content="https://yoonimo.com/dashboard/marketing" />
					<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
					<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
				</Helmet>
				<Container fluid className="bg-white m-26px p-0" id="dashboard-board">
					<Row className="p-0 m-0 ">
						<Col xs={12} className="px-20px">
							<InquiriesToolbar fetch={fetch} numSelected={selected.length} selected={selected} handleDelete={handleDelete} />
							<TableContainer>
								<Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
									<MarketingPageHead
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onSelectAllClick={handleSelectAllClick}
										onInquiriesort={handleInquiriesort}
										rowCount={rows.length}
									/>
									<TableBody>
										{stableSort(rows, getComparator(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((row, index) => {
												const isItemSelected = isSelected(index);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role="checkbox"
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}
														className={+index % 2 ? 'bg-light-dark table-row-link' : 'bg-body-back table-row-link'}
														onClick={e => handleRowClick(e, row)}
													>
														<TableCell padding="checkbox" onClick={e => e.stopPropagation()} className="table-checkbox-wrapper">
															<Checkbox
																checked={isItemSelected}
																inputProps={{
																	'aria-labelledby': labelId,
																}}
																onClick={event => handleClick(event, index, row.id)}
															/>
														</TableCell>
														<TableCell align="left" id={labelId} scope="row" className="text-capitalize">
															{row.loanType ? row.loanType : '-'}
														</TableCell>
														<TableCell align="left" id={labelId} scope="row">
															{row.address ? row.address : '-'}
														</TableCell>
														<TableCell align="left">{row.loanAmt ? priceTag(row.loanAmt) : '-'}</TableCell>
													</TableRow>
												);
											})}
										{/* {emptyRows > 0 && (
											<TableRow
												style={{
													height: (dense ? 33 : 53) * emptyRows,
												}}
											>
												<TableCell colSpan={6} />
											</TableRow>
										)} */}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 20, 50]}
								component="div"
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
							<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
						</Col>
					</Row>

					{/* tiny MCE */}

					<Row>
						<Col xs={'12'} className="d-flex justify-content-center">
							<button className="m-3 primary-button mr-1 p-2 btn btn-primary" onClick={createCode}>
								{/* <Button className="m-3" onClick={createCode}> */}
								create
							</button>
							<button className="m-3 primary-button mr-1 p-2 btn btn-primary" onClick={copyCodeToClipboard}>
								copy to clipboard
							</button>
						</Col>
					</Row>

					<Row>
						<Col xs={'6'}>
							<textarea value={htmlCode} cols="30" rows="10" disabled style={{ width: '100%', height: '700px' }}></textarea>
						</Col>
						<Col className="d-flex justify-content-center" xs={'6'}>
							<Editor
								apiKey="7t2oc8rnnowsgcxlophrt8dj8wy2e25czy5ikgda0ga7a7be"
								plugins="wordcount"
								init={{
									toolbar: '',
									menubar: '',
									width: 700,
									height: 700,
									// plugins: 'code',
									// toolbar:
									// 	'undo redo | formatselect | bold italic backcolor | \
									//       alignleft aligncenter alignright alignjustify | \
									//       bullist numlist outdent indent | removeformat | code ',
								}}
								value={htmlCode}
								// onEditorChange={handleEditorChange}
							/>
						</Col>
					</Row>
				</Container>
			</DashboardLayout>
		);
	}
};

export default KRMarketingPage;
