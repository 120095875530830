import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import Judgements from '../../../../../components/Borrowers/Judgements';
import SubjectPropertyAddr from '../../../../../components/Borrowers/SubjectPropertyAddr';
import Zoning from '../../../../../components/Borrowers/Zoning';
import Construction from '../../../../../components/Borrowers/Construction';
import MortgageBalance from '../../../../../components/Borrowers/MortgageBalance';
import LoanType from '../../../../../components/Borrowers/LoanType';
import BorrowingEntity from '../../../../../components/Borrowers/BorrowingEntity';
import PropertyType from '../../../../../components/Borrowers/PropertyType';
import Purchase from '../../../../../components/Borrowers/Purchase';
import FixFlip from '../../../../../components/Borrowers/FixFlip';
import Purpose from '../../../../../components/Borrowers/Purpose';
import ContactInformation from '../../../../../components/Borrowers/ContactInformation';
import Spinner from '../../../../../components/Spinner';
import DashboardLayout from '../../../../../components/dashboard/DashboardLayout';
// import { Container, Row, Col, Button } from 'react-bootstrap';
const KRUserUpdateInquiryPage = props => {
	const currentUserData = props.user.data;
	const postId = props.match.params.id;

	const [Warning, setWarning] = useState({
		message: '',
		type: '',
	});

	const emptyTemplate = {
		loanType: '',
		borrowingEntity: '',
		borrowingEntity_desc: '',
		typeOfProperty: '',
		typeOfProperty_desc: '',
		subjectPropertyAddr: {
			line1: '',
			line2: '',
			city: '',
			state: '',
			zip: '',
			country: 'United States',
			APN: '',
		},
		zoning: '',
		lotSize: '',
		landState: '',
		ownership: '',
		entitlementProcess: '',
		includingLandCost: '',
		landPurchase: '',
		landDownPayment: '',
		marketValueAfterCompletion: '',
		landPurchasedDate: '',
		landPurchasedPrice: '',
		landLoanBalance: '',
		description: '',
		occupancy: '',
		loanAmt: '',
		ltv: '',
		downPayment: '',
		sellerCarry: '',
		escrowOpened: '',
		fixUpCost: '',
		afterRemodelingValue: '',
		estTimeToFix: '',
		// finished edit above
		propertyRemodelFixingExp: '',
		howManyProperties: '',
		howManyProperties_when: '',
		purpose: '',
		reqLoanAmt: '',
		currentEstValue: '',
		interestRate: '',
		term: '',
		purchasedDate: '',
		purchasedPrice: '',
		mortgageBalance: {
			index: [
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
			],
		},
		appraisalReport: '',
		appraisalValue: '',
		environmentalReport: '',
		judgementsOrLiensRecoreded: '',
		totalAmtRecoreded: '',
		dateRecorded: '',
		describeReason: '',
		paybackPlan: '',
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		officeMemo: '',
		images: [],
	};
	const [data, setData] = useState(emptyTemplate);
	const radioHandler = (e, name) => {
		if (data.status === 'pending') {
			const newName = e?.target?.name ? e.target.name : name;
			const newValue = e?.target?.value ? e.target.value : typeof e === 'object' ? e.target.value : e;
			setData({ ...data, [newName]: newValue });
		}
	};

	const propertyAddressHandler = e => {
		if (data.status === 'pending') {
			setData({ ...data, subjectPropertyAddr: { ...data.subjectPropertyAddr, [e.target.name]: e.target.value } });
		}
	};

	useEffect(() => {
		axios.get(`/api/inquiry/get/property/${postId}`).then(res => {
			if (res.data && res.data.success) {
				if (res.data.property === null) {
					props.history.push('/');
				} else {
					setData(res.data.property);
				}
			} else {
				alert('Failed to fetch property datas');
			}
		});
	}, []);

	const handleSubmit = async (e, status) => {
		e.preventDefault();
		let body = Object.assign({}, data);
		body['status'] = 'pending';
		axios.put('/api/inquiry/put/user/property/', body).then(response => {
			if (response.data.success) {
				setWarning({ message: 'Updated successfully.', type: 'success' });
			} else {
				setWarning({ message: 'Failed to update the inquiry.', type: 'danger' });
			}
			window.scrollTo(0, 0);
		});
	};

	const mortgageBalanceInputsHandle = (e, i) => {
		const newValue = e.target.value;
		const newName = e.target.name;
		if (data.status === 'pending') {
			setData(prevState => {
				prevState.mortgageBalance.index[i][newName] = newValue;
				return {
					...prevState,
				};
			});
		}
	};
	const loanTypeRadioHandle = (e, steps) => {
		const newValue = e.target.value;
		if (data.status === 'pending') {
			setData(prevState => {
				prevState.loanType = newValue;
				return {
					...prevState,
				};
			});
		}
	};
	const contactInputsHandle = e => {
		if (data.status === 'pending') {
			setData({ ...data, phoneNumber: e });
		}
	};

	const purchaseLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purchase handle={radioHandler} data={data} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const refiCashOutLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purpose data={data} handle={radioHandler} />
			<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
			<Judgements data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const fixFlipLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purchase handle={radioHandler} data={data} />
			<FixFlip handle={radioHandler} data={data} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const secondLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purpose data={data} handle={radioHandler} />
			<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
			<Judgements data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const thirdLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purpose data={data} handle={radioHandler} />
			<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
			<Judgements data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const constructionLoan = () => (
		<>
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Zoning data={data} handle={radioHandler} />
			<Construction data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const bridgeLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purpose data={data} handle={radioHandler} />
			<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
			<Judgements data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	return (
		<DashboardLayout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Borrowers - Yoonimo</title>
				<title
					data-react-helmet="true"
					property="og:description"
					content="Hard money lending made easy! At Yoonimo, borrowers can submit their property on our page for loan inquiries. All you need is an estimated appraisal value, loan purpose, address, and you’re all done!"
				/>
				<link rel="canonical" data-react-helmet="true" href="https://yoonimo.com/borrowers/" />
				<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="Borrowers - Yoonimo" />
				<title
					data-react-helmet="true"
					property="og:description"
					content="Hard money lending made easy! At Yoonimo, borrowers can submit their property on our page for loan inquiries. All you need is an estimated appraisal value, loan purpose, address, and you’re all done!"
				/>
				<meta data-react-helmet="true" property="og:url" content="https://yoonimo.com/borrowers/" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
			</Helmet>
			{data ? (
				<Container fluid className="m-26px p-0" id="dashboard-board">
					<Row className="p-0 m-0 justify-content-center">
						<Col xs={12} className="p-0">
							<div id="dashboard-form">
								<Row className="p-0 m-0">
									<Col xs={12} className="p-0">
										<span className="d-block font-weight-500 w-100">Update Inquiry</span>
									</Col>
									{Warning.message ? (
										<Col xs={12} className="pb-2 p-0">
											<Alert className="form-input" variant={Warning.type}>
												{Warning.message}
											</Alert>
										</Col>
									) : null}
									<Col xs={12} md={11} lg={12} className="p-0">
										{/* 0 step */}
										{<LoanType handle={loanTypeRadioHandle} data={data} />}
										{<BorrowingEntity handle={radioHandler} data={data} />}
										{data.loanType.length > 0 ? (
											<>
												{data.loanType === 'purchase' ? purchaseLoan() : null}
												{data.loanType === 'refinance' ? refiCashOutLoan() : null}
												{data.loanType === 'fix & flip/Rehab' ? fixFlipLoan() : null}
												{data.loanType === '2nd' ? secondLoan() : null}
												{data.loanType === '3rd' ? thirdLoan() : null}
												{data.loanType === 'construction/project' ? constructionLoan() : null}
												{data.loanType === 'bridge/mezz' ? bridgeLoan() : null}
											</>
										) : null}
									</Col>
								</Row>
								<Row className="mt-2">
									<Col>
										<button
											className="primary-button no-radius"
											disabled={data.status === 'pending' ? false : true}
											onClick={data.status === 'pending' ? handleSubmit : ''}
										>
											{data.status === 'pending' ? 'Submit' : 'You can Not Edit'}
										</button>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Container>
			) : (
				<Spinner />
			)}
		</DashboardLayout>
	);
	// } else {
	// 	return <Redirect to={'/'} />;
	// }
};

export default KRUserUpdateInquiryPage;
