import React, { useEffect } from 'react';
import Layout from '../../components/kr/global/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
const KRLoanParametersPage = props => {
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Loan Parameters - Yoonimo</title>
				<meta name="description" content="" />
				<link rel="canonical" data-react-helmet="true" href="https://yoonimo.com/kr/lending-guidelines" />
				<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="Loan Parameters - Yoonimo" />
				<meta property="og:description" content="" />
				<meta data-react-helmet="true" property="og:url" content="https://yoonimo.com/kr/lending-guidelines" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
			</Helmet>
			<section>
				<Container id="loanParameters">
					<Row>
						<Col>
							<h1 className="table-title">Loan Parameters</h1>
							<table class="tableizer-table mb-5" border="1" cellSpacing="0">
								<tr>
									<td rowSpan="2">&nbsp;</td>
									<td>
										<img alt="invest property" src="/media/invest-property.png" width="80px" className="rounded mx-auto d-block m-3" />
									</td>
									<td>
										<img alt="fix_rental" src="/media/fix_rental.png" width="80px" className="rounded mx-auto d-block m-3" />
									</td>
									<td>
										<img alt="bridge" src="/media/bridge.png" width="80px" className="rounded mx-auto d-block m-3" />
									</td>
									<td>
										<img alt="land/construction" src="/media/land_construction.png" width="80px" className="rounded mx-auto d-block m-3" />
									</td>
									<td>
										<img alt="project/spec" src="/media/project.png" width="80px" className="rounded mx-auto d-block m-3" />
									</td>
									<td>
										<img alt="note" src="/media/note.png" width="80px" className="rounded mx-auto d-block m-3" />
									</td>
									<td>
										<img alt="business equipment" src="/media/business_equip.png" width="80px" className="rounded mx-auto d-block m-3" />
									</td>
								</tr>

								<tr>
									<td className="table-header px-3">INVEST. PROP</td>
									<td className="table-header px-3">FIX & RENTAL</td>
									<td className="table-header px-3">BRIDGE/MEZZ</td>
									<td className="table-header px-3">LAND/CONST.</td>
									<td className="table-header px-3">PROJ/SPEC</td>
									<td className="table-header px-3">NOTE</td>
									<td className="table-header px-3">BUS/EQUIP</td>
								</tr>
								<tbody>
									<tr>
										<td className="table-header px-3">TERM</td>
										<td className="px-3">1YR - 30YRS</td>
										<td className="px-3">1YR</td>
										<td className="px-3">1YR - 2YRS</td>
										<td className="px-3">1YR - 2YRS</td>
										<td className="px-3">1YR - 2YRS</td>
										<td className="px-3">NOTE TERM</td>
										<td className="px-3">6MO - 5YRS</td>
									</tr>
									<tr>
										<td className="table-header px-3">MIN AMT.</td>
										<td className="px-3">$150,000 </td>
										<td className="px-3">$150,000 </td>
										<td className="px-3">$250,000 </td>
										<td className="px-3">$250,000 </td>
										<td className="px-3">$1,000,000 </td>
										<td className="px-3">$150,000 </td>
										<td className="px-3">$50,000 </td>
									</tr>
									<tr>
										<td className="table-header px-3">MAX AMT.</td>
										<td className="px-3">$75M</td>
										<td className="px-3">$50M</td>
										<td className="px-3">$150M</td>
										<td className="px-3">$300M</td>
										<td className="px-3">$300M</td>
										<td className="px-3">$50M</td>
										<td className="px-3">$25M</td>
									</tr>
									<tr>
										<td className="table-header px-3">RATE</td>
										<td className="px-3">4.95% ~</td>
										<td className="px-3">4.5%~</td>
										<td className="px-3">7.5%~</td>
										<td className="px-3">4.5%~</td>
										<td className="px-3">6.5%~</td>
										<td className="px-3">DISC.</td>
										<td className="px-3">Prim+2.5%~</td>
									</tr>
									<tr>
										<td className="table-header px-3">LTVLTC</td>
										<td className="px-3">70%</td>
										<td className="px-3">90%</td>
										<td className="px-3">75%</td>
										<td className="px-3">50%</td>
										<td className="px-3">65%</td>
										<td className="px-3">70%</td>
										<td className="px-3">90%</td>
									</tr>
									<tr>
										<td className="table-header px-3">CLTV</td>
										<td className="px-3">70%</td>
										<td className="px-3">100%</td>
										<td className="px-3">75%</td>
										<td className="px-3">50%</td>
										<td className="px-3">65%</td>
										<td className="px-3">n/a</td>
										<td className="px-3">n/a</td>
									</tr>
									<tr>
										<td className="table-header px-3">ARV</td>
										<td className="px-3">n/a</td>
										<td className="px-3">75%</td>
										<td className="px-3">n/a</td>
										<td className="px-3">n/a</td>

										<td className="px-3">65%</td>
										<td className="px-3">n/a</td>
										<td className="px-3">n/a</td>
									</tr>
									<tr>
										<td className="table-header px-3">FEE</td>
										<td className="px-3">2% - 4%</td>
										<td className="px-3">2% - 4%</td>
										<td className="px-3">2% - 4%</td>
										<td className="px-3">3% - 5%</td>
										<td className="px-3">3% - 5%</td>
										<td className="px-3">3% - 4%</td>
										<td className="px-3">2% - 10%</td>
									</tr>
									<tr>
										<td className="table-header px-3">FICO MIN</td>
										<td className="px-3">620</td>
										<td className="px-3">620</td>
										<td className="px-3">620</td>
										<td className="px-3">620</td>
										<td className="px-3">680</td>
										<td className="px-3">n/a</td>
										<td className="px-3">680</td>
									</tr>
									<tr>
										<td className="table-header px-3">EXPERIENCE</td>
										<td className="px-3">Preferred</td>
										<td className="px-3">Preferred</td>
										<td className="px-3">No</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Preferred</td>
										<td className="px-3">Yes</td>
									</tr>
									<tr style={{ visibility: 'hidden' }}>
										<td className="px-3">&nbsp;</td>
										<td className="px-3">&nbsp;</td>
										<td className="px-3">&nbsp;</td>
										<td className="px-3">&nbsp;</td>
										<td className="px-3">&nbsp;</td>
										<td className="px-3">&nbsp;</td>
										<td className="px-3">&nbsp;</td>
										<td className="px-3">&nbsp;</td>
									</tr>
									<tr>
										<td className="table-header px-3">PURCHASE</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
									</tr>
									<tr>
										<td className="table-header px-3">REFI/CASH</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">n/a</td>
										<td className="px-3">n/a</td>
										<td className="px-3">n/a</td>
										<td className="px-3">Yes</td>
									</tr>
									<tr>
										<td className="table-header px-3">2ND & 3RD</td>
										<td className="px-3">Yes</td>
										<td className="px-3">n/a</td>
										<td className="px-3">Yes</td>
										<td className="px-3">n/a</td>
										<td className="px-3">n/a</td>
										<td className="px-3">n/a</td>
										<td className="px-3">n/a</td>
									</tr>
									<tr>
										<td className="table-header px-3">NOD/NTS/BK</td>
										<td className="px-3">Yes</td>
										<td className="px-3">n/a</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">Yes</td>
										<td className="px-3">n/a</td>
									</tr>
									<tr rowSpan="6">
										<td className="table-header px-3">PROPERTY TYPE</td>
										<td className="px-3"> SFR(Business)2-4U N/O/O Multi-Family Commercial Industrial Office Retail</td>
										<td className="px-3"> SFR(Business) 2-4U N/O/O Multi-Family Commercial Industrial Office</td>
										<td className="px-3"> Res. Inv. Prop Multi-Family Industrial Commercial</td>
										<td className="px-3"> Residential Multi-Family Industrial Commercial Raw Land Entitled Land Farm & Ag</td>
										<td className="px-3"> Development Energy Proj. Res & Senior Housing Joint Venture Pref'd Equity</td>
										<td className="px-3"> Residential Multi-Family Commercial Industrial Retail Center</td>
										<td className="px-3"> Business Business Prop Equipment Working Cap Factoring</td>
									</tr>
									<tr>
										<td rowSpan="2" className="table-header px-3">
											SPECIALTY FARM / AG
										</td>
										<td colSpan="7" className="px-3">
											Mobile Home Park, Religious Facility, Farm Land, Agricultural Business & Land, Recreational
										</td>
									</tr>
									<tr>
										<td colSpan="7" className="px-3">
											Large Acreage Land, Cannabis Business/Property, Environmental Issues.
										</td>
									</tr>
									<tr>
										<td colSpan="8" className="legal-rights p-4">
											All rates, programs and pricing are estimated and subject to change without notice. This Lending Parameter is intended for use by
											mortgage professionals only and is not an advertisement under section 226.24 of Regulation Z and is not intended for use by the general
											public. <strong>Yoonimo uses owner occupied residential property for business purpose only.</strong> Yoonimo is a both direct lender and
											a broker and is dba of Cozmo Realty Inc.; all loans are subject to review by Yoonimo and its investors and other funding sources and may
											be approved or declined in its sole discretion. @copy rights 2021, Yoonimo. All rights reserved.
										</td>
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default KRLoanParametersPage;
