import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Arrowhead } from '../global/svgs';
import Alert from 'react-bootstrap/Alert';

const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL;

const MyForm = ({ status, message, onValidated }) => {
	const [subscriber, setSubscriber] = useState();

	const onFieldHandler = e => {
		setSubscriber(e.target.value);
	};

	const onSubmit = e => {
		e.preventDefault();

		subscriber &&
			subscriber.indexOf('@') > -1 &&
			onValidated({
				EMAIL: subscriber,
			});
	};

	return (
		<form onSubmit={onSubmit}>
			{status ? (
				<Alert variant={status === 'sending' || status === 'success' ? 'success' : 'danger'}>
					<div dangerouslySetInnerHTML={{ __html: message }}></div>
				</Alert>
			) : null}
			<Form.Control required name="email" autoComplete="email" value={subscriber} onChange={onFieldHandler} type="email" placeholder="Email Address" />
			<Button type="submit" className="primary-button  p-2 px-4 mt-10px">
				<span>Subscribe</span>
			</Button>
		</form>
	);
};

const Footer = props => {
	let { contactRef } = props;
	return (
		<>
			<footer className="py-50px">
				<Container fluid id="footer">
					<Row className="justify-content-center">
						<Col sm={12} md={10}>
							<Row>
								<Col xs={12} sm={6} md={3} className="mb-xs-30px">
									{/* <h4 className="mb-16px">Subscribe to Newsletter</h4>
									<MailchimpSubscribe
										url={MAILCHIMP_URL}
										render={({ subscribe, status, message }) => <MyForm status={status} message={message} onValidated={formData => subscribe(formData)} />}
									/> */}
									<h4 className="mb-16px">추천및 소개</h4>
									<span className="para-font-color mb-2 d-block">Refer a friend and get referral fee </span>
									<Link to="/dashboard/referral">
										<Button className="primary-button">등록</Button>
									</Link>
								</Col>
								<Col xs={12} sm={6} md={3} className="mb-xs-30px">
									<h4 className="mb-16px">Important Links</h4>
									<ul className="no-bullet para-font-color">
										<li>
											<Arrowhead />
											<span>
												<Link to={'/kr'}>홈</Link>
											</span>
										</li>
										<li>
											<Arrowhead />
											<span>
												<Link to={'/kr/investors'}>투자자</Link>
											</span>
										</li>
										<li>
											<Arrowhead />
											<span>
												<Link to={'/kr/borrower'}>융자 신청자</Link>
											</span>
										</li>
										{/* <li>
											<Arrowhead />
											<span>
												<Link to={'/kr/blog'}>블로그</Link>
											</span>
										</li> */}
										{/* <li>
											<Arrowhead />
											<span>
												<Link to={'/funded'}>Funded</Link>
											</span>
										</li> */}
									</ul>
								</Col>
								<Col xs={12} sm={6} md={3} className="mb-xs-30px">
									<h4 className="mb-16px">Company</h4>
									<ul className="no-bullet para-font-color">
										<li>
											<Arrowhead></Arrowhead>
											<span>
												<Link to={'/kr/about-us'}>회사 소개</Link>
											</span>
										</li>
										<li>
											<Arrowhead></Arrowhead>
											<span>
												<a
													href={'/kr/#home-contact-us'}
													onClick={e => {
														if (window.location.pathname === '/') {
															e.preventDefault();
															window.location.hash = '#home-contact-us';
															contactRef.current.scrollIntoView();
														}
													}}
												>
													문의 하기
												</a>
											</span>
										</li>
										<li>
											<Arrowhead></Arrowhead>
											<span>
												<Link to={'/kr/how-it-works'}>업무 추진 방법</Link>
											</span>
										</li>
										<li>
											<Arrowhead></Arrowhead>
											<span>
												<Link to={'/kr/faqs'}>FAQs</Link>
											</span>
										</li>
										<li>
											<Arrowhead></Arrowhead>
											<span>
												<Link to={'/kr/loan-parameters'}>Loan Parameters</Link>
											</span>
										</li>
									</ul>
								</Col>
								<Col xs={12} sm={6} md={3}>
									<h4 className="mb-16px">Reach Us</h4>

									<a className="no-deco d-block mb-2 para-font-color" href="mailto:john@yoonimo.com">
										john@yoonimo.com
									</a>
									<a href="tel:213-804-9555" className="no-deco para-font-color mb-2 d-block">
										213-804-9555
									</a>
									<span className="para-font-color mb-2 d-block">
										{/* <strong className="dark-font-color">Address:</strong>{' '} */}
										<a
											href="https://www.google.com/maps/place/3850+Wilshire+Blvd+%23303,+Los+Angeles,+CA+90010/@34.0613555,-118.3131779,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2b88555300869:0xc6a33952d7d01f17!8m2!3d34.0613511!4d-118.3109892"
											target="_blank"
											rel="noopener noreferrer"
										>
											3850 Wilshire Blvd. Suite 303 <br />
											Los Angeles, CA 90010
										</a>
									</span>
									<span className="para-font-color d-block">DRE #: 02203661</span>
									<div id="socialmedia" className="d-flex">
										<div id="linkedin">
											<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/cozmo-us/">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="22"
													height="22"
													fill="currentColor"
													className="bi bi-linkedin hover"
													viewBox="0 0 16 16"
												>
													<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
												</svg>
											</a>
										</div>
										<div id="instagram">
											<a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/cozmo.us/">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="22"
													height="22"
													fill="currentColor"
													className="bi bi-instagram hover"
													viewBox="0 0 16 16"
												>
													<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
												</svg>
											</a>
										</div>
									</div>
									<div id="footnote">
										{window.location.pathname === '/kr' ? (
											<>
												<span className="d-block mb-1">
													<span className="asterisk">*</span> Fix & Flip cases only. 90% LTC, 100% Remodeling cost, upto 75% ARV.
												</span>
												<span className="d-block mb-1">
													<span className="asterisk">**</span> Lending in CA only.
												</span>
											</>
										) : (
											<span className="d-block mb-1">
												<span className="asterisk">*</span> Lending in CA only.
											</span>
										)}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</footer>
			<Container fluid id="copyright" className="bg-white">
				<Row className="justify-content-center no-deco">
					<Col md={10}>
						<Row>
							<Col className="text-center text-md-left p-0" xs={12} sm={4} md={4}>
								<span>
									ⓒ 2020 | <Link to={'/kr'}>Yoonimo</Link>
								</span>
							</Col>
							<Col xs={12} sm={8} md={8} className="text-md-right pr-sm-2 p-md-0 text-center text-sm-right">
								<span>
									<Link to={'/kr/terms-and-conditions'}>Terms & Conditions</Link>
								</span>
								{` `}
								<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="5" cy="5.5" r="4.5" stroke="#666666" />
									<circle cx="4.99998" cy="5.49992" r="1.66667" fill="#C4C4C4" />
								</svg>
								{` `}
								<span>
									<Link to={'/kr/privacy-policy'}>Privacy Policy</Link>
								</span>
								{` `}
								<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="5" cy="5.5" r="4.5" stroke="#666666" />
									<circle cx="4.99998" cy="5.49992" r="1.66667" fill="#C4C4C4" />
								</svg>
								{` `}
								<span>
									<Link to={'/kr/fee-schedule'}>Fee Schedule</Link>
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Footer;
