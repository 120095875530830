import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { CenterStick, BRStick } from '../../components/global/svgs';
import { Link } from 'react-router-dom';
import { Arrowhead } from '../../components/global/svgs';
import Layout from '../../components/kr/global/Layout';

const KRAboutUsPage = props => {
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">회사 소개 - Yoonimo</title>
				<link data-react-helmet="true" rel="canonical" href="https://yoonimo.com/kr/about-us/" />
				<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="회사 소개 - Yoonimo" />
				<meta
					data-react-helmet="true"
					property="og:description"
					content="The mission of Yoonimo is to provide investors and beneficiaries with the best possible trust deed investment opportunities. We can bring you the best deals in high return investment funds, commercial real estate loans, and investment properties in California."
				/>
				<meta data-react-helmet="true" property="og:url" content="/kr/about-us/" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
				<meta data-react-helmet="true" property="og:image" content="/about/ezgif.com-webp-to-jpg.jpg" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
			</Helmet>
			<div className="bg-illust-wrapper">
				<figure className="AboutUs-top-spill" />
				<figure className="AboutUs-bottom-spill" />
			</div>
			<section id="about-us">
				<Container>
					<Row className="mt-71p">
						<Col className="text-center">
							<h2 className="headline title1">회사 소개</h2>
							<span className="subhead retina-font">당신에게 중요한 곳에 투자하세요.</span>
							<div className="mt-2">
								<CenterStick />
							</div>
							<Col className="col-lg-10 offset-lg-1 retina-font">
								<p className="mt-3">
									Yoonimo는 광범위한 투자자 그룹 및 자금 출처의 네트워크를 통해 주거 및 상업용 부동산 대출 전문회사 입니다. 당사의 풍부한 경험과 준비성 및 유연성
									으로 귀하의 요구를 충족할 수 있는 능력은 당사를 귀하가 선호하는 최적의 대출 기관으로 만듭니다.
								</p>
								<p>Yoonimo에 연락하시면 귀하의 요청과 요구 사항을 주의 깊게 듣고 다년간의 경험을 가진 업계 전문가가 검토하고 최상의 솔루션을 제시할 것입니다.</p>
							</Col>
							<div className="button-wrapper mt-4">
								<Button className="primary-button  mr-3 no-deco">
									<Link to="/kr/investors">
										<span className="button-inner-text">인벤토리 보기</span>
									</Link>
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section id="loan-products" className="my-4">
				<Container>
					<Row>
						<Col className=" text-center retina-font">
							<h2>대출 상품</h2>
							<p>
								<span className="badge badge-pill badge-dark">Purchase</span> <span className="badge badge-pill badge-dark">refinance</span>
								<span className="badge badge-pill badge-dark">2nd</span> <span className="badge badge-pill badge-dark">3rd</span>
								<span className="badge badge-pill badge-dark">Bridge/Mezzarnine</span>
								<span className="badge badge-pill badge-dark">Rehab/Fix&Flip (Short & Long terms)</span>
								<span className="badge badge-pill badge-dark">Residential Rental (Short & 30 yr)</span>
								<span className="badge badge-pill badge-dark">Construction</span> <span className="badge badge-pill badge-dark">Land</span>
								<span className="badge badge-pill badge-dark">Agricultural</span>
								<span className="badge badge-pill badge-dark">Project Development Financing</span>
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="diffrent">
				{/* <Container className="mt-120p mb-5"> */}
				<Container>
					<Row>
						<Col className="col-12 col-lg-6 mt-3 mb-5">
							<img alt="man with graph chart illust" className="img-fluid" src="/aboutUsPage/cuate.png"></img>
						</Col>

						<Col className="col-12 col-lg-6 retina-font">
							<h2>우리는 어떻게 다른가</h2>
							<BRStick />
							<ul className="mt-4 no-bullet">
								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">전문 에스크로 담당자는 거래 시작과 종료의 감독합니다.</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">에스크로 및 소유권 보험은 모든 거래와 함께 동시 등록됩니다.</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">
										차용인의 채무상환 약속 불이행시 당사는 자체 선도적인 압류 서비스를 제공하여 투자자가 번거롭지 않도록 압류 절차를 신속 진행하고 완료합니다.
									</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">
										투자자/수혜자는 제출과 관련된 모든 문서(1003 양식, 신용 보고서, 감정서, 예비 소유권 보고서, 에스크로 지침, 임대 계약서, 소득 문서 등)를 즉시
										받게 됩니다.
									</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">투자자/수혜자는 자신의 대출을 상환할 수 있는 충분한 부동산 담보제공자를 선별 제공합니다.</span>
								</li>

								<li className="shadow p-3 mb-2 bg-white rounded d-flex">
									<div className="my-auto mr-10px">
										<Arrowhead />
									</div>
									<span className="d-block">
										우리는 캘리포니아의 투자용 부동산 및 상업용 부동산에 대해서만 대출을 하고 모든 부동산을 가까운 상태로 유지합니다.
									</span>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default KRAboutUsPage;
