import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import FAQ from '../components/FAQs/FAQ';
import Layout from '../components/global/Layout';

const FAQsPage = props => {
	const currentUserData = props.user.data;
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [activeAccordion, setActiveAccordion] = useState(0);

	const activateAccordion = target => {
		if (activeAccordion === target) {
			setActiveAccordion(0);
		} else {
			setActiveAccordion(target);
		}
	};
	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Frequently Asked Questions - Yoonimo</title>
				<meta
					data-react-helmet="true"
					name="description"
					content="At Yoonimo, we provide you with high return investment funds, commercial real estate loans and investment properties. We are committed to delivering a high level of expertise, customer service, and attention to detail in hard money lending."
				/>
				<link data-react-helmet="true" rel="canonical" href="https://yoonimo.com/faqs" />
				<meta data-react-helmet="true" property="og:locale" content="en_US" />
				<meta data-react-helmet="true" property="og:type" content="website" />
				<meta data-react-helmet="true" property="og:title" content="Frequently Asked Questions - Yoonimo" />
				<metas
					data-react-helmet="true"
					property="og:description"
					content="At Yoonimo, we provide you with high return investment funds, commercial real estate loans and investment properties. We are committed to delivering a high level of expertise, customer service, and attention to detail in hard money lending."
				/>
				<meta data-react-helmet="true" property="og:url" content="https://yoonimo.com/faqs" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
			</Helmet>
			<main>
				<FAQ activeAccordion={activeAccordion} activateAccordion={activateAccordion} />
			</main>
		</Layout>
	);
};

export default FAQsPage;
