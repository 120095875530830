import React, { useEffect } from 'react';
import { useLocation, Link, withRouter } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import useResize from '../../utils/useResize';
import axios from 'axios';
import Translation from '../../Translation/Translation';

const MainNavbar = props => {
	const currentUserData = props.currentUserData;
	const { width } = useResize();
	const location = useLocation();

	const handleScroll = () => {
		if (window.scrollY > 60) {
			document.querySelector('.navbar').className = 'navbar navbar-expand-md navbar-light fixed-top scroll px-5';
		} else {
			document.querySelector('.navbar').className = 'navbar navbar-expand-md navbar-light fixed-top px-5';
		}
	};

	useEffect(() => {
		if (location.pathname === '/how-it-works') {
			document.addEventListener('scroll', handleScroll);
			return () => {
				document.removeEventListener('scroll', handleScroll);
			};
		} else {
			document.querySelector('.navbar').className = 'navbar navbar-expand-md navbar-light fixed-top scroll px-5';
		}
	}, [location.pathname]);

	const logoutHandler = () => {
		axios.get(`/api/users/logout`).then(response => {
			if (response.data.success) {
				props.history.push('/kr/login');
			} else {
				alert('Log Out Failed');
			}
		});
	};

	return (
		<>
			<div className={location.pathname === '/' ? 'display-none' : 'mask-spacing'}></div>
			<Translation />
			<Navbar collapseOnSelect className="px-5" expand="md" fixed="top" id={width >= 768 ? 'desktop-navbar' : 'mobile-navbar'}>
				<Navbar.Brand as={Link} to="/kr" className="nav-logo-placement">
					{width >= 768 ? (
						<img alt="Yoonimo logo" src="/logos/yoonimo_600x102.png" width="auto" height="51px" />
					) : (
						<img alt="Yoonimo logo" src="/logos/yoonimo_600x102.png" width="auto" height="40px" />
					)}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav">
					<svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="5" width="15" height="3" rx="1.5" fill="#03946d" />
						<rect y="6" width="20" height="3" rx="1.5" fill="#03946d" />
						<rect x="10" y="12" width="10" height="3" rx="1.5" fill="#03946d" />
					</svg>
				</Navbar.Toggle>

				<Navbar.Collapse id="responsive-navbar-nav">
					{currentUserData && currentUserData.isAuth ? (
						width >= 768 ? (
							<div className="navbar-placeholder-auth"></div>
						) : (
							''
						)
					) : (
						<div className="navbar-placeholder-unauth"></div>
					)}
					<Nav className="ml-auto mr-auto">
						<Nav.Link as={Link} className="navbar-cta" to="/kr/investors">
							투자자
						</Nav.Link>
						<Nav.Link as={Link} className="navbar-cta" to="/kr/borrower">
							융자 신청자
						</Nav.Link>
						<Nav.Link as={Link} className="navbar-cta" to="/kr/funded">
							투자 완료
						</Nav.Link>
					</Nav>

					{currentUserData && currentUserData.isAuth ? (
						width >= 768 ? (
							<Nav>
								<NavDropdown
									title={
										<div className="profile-pill">
											<div className="profile-wrapper">
												<img alt="user default" src="/user/default.png" width="40px" height="40px" />
											</div>
											<div className="down-carrot-wrapper">
												<svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M0.5 1L8.5 9L16.5 1" stroke="#666666" />
												</svg>
											</div>
										</div>
									}
									id="nav-acc-dropdown"
									alignRight
								>
									<NavDropdown.Item className="no-deco dark-font" as={Link} to={'/dashboard/my-inquiries'}>
										대시보드
									</NavDropdown.Item>

									<NavDropdown.Divider />
									<NavDropdown.Item onClick={logoutHandler}>로그아웃</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						) : (
							<Nav>
								<Nav.Link as={Link} className="mobile-nav-profile" to="/dahsboard/my-inquiries">
									<img alt="user default" src="/user/default.png" width="40px" height="40px" />
								</Nav.Link>
								<div className="d-inline-flex align-content-center justify-content-center mobile-nav-cta-wrapper">
									<Nav.Link className="navbar-cta p-4" href="/dashboard/my-inquiries">
										대시보드
									</Nav.Link>
									{/* <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> */}
									<Nav.Link className="navbar-cta p-4" onClick={logoutHandler}>
										로그아웃
									</Nav.Link>
								</div>
							</Nav>
						)
					) : (
						<>
							<Nav.Link as={Link} className="navbar-cta" to="/kr/login">
								로그인
							</Nav.Link>
							<Link to="/kr/register">
								<Button className="primary-button">회원 가입</Button>
							</Link>
						</>
					)}
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default withRouter(MainNavbar);
