import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import Layout from '../../components/kr/global/Layout';

const useStyles = makeStyles(theme => ({
	root: {
		height: 'calc(100vh - 4rem)',
		flexGrow: 1,
	},
	center: {
		textAlign: 'center',
	},
	linkWrapper: {
		textAlign: 'center',
		marginTop: '40px',
	},
}));

const KRNotFoundPage = props => {
	const classes = useStyles();
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Container component="main">
				<Helmet>
					<meta data-react-helmet="true" charSet="utf-8" />
					<title data-react-helmet="true">404 Not Found - Yoonimo</title>
					<meta data-react-helmet="true" name="description" content="" />
					<link rel="canonical" data-react-helmet="true" href={`https://yoonimo.com/kr/404`} />
					<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
					<meta data-react-helmet="true" property="og:type" content="article" />
					<meta data-react-helmet="true" property="og:title" content={`404 Not Found - Yoonimo`} />
					<meta data-react-helmet="true" property="og:description" content="" />
					<meta data-react-helmet="true" property="og:url" content={`https://yoonimo.com/kr/property/404`} />
					<meta data-react-helmet="true" property="og:site_name" content="Yoonimo" />
					<meta data-react-helmet="true" property="og:image" content="https://yoonimo.com/og_yoonimo.png" />
				</Helmet>
				<Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
					<Grid item xs={6}>
						<Grid item xs={12} className={classes.center}>
							<Typography component="h1" variant="h1">
								404
							</Typography>
						</Grid>
						<Grid item xs={12} className={classes.center}>
							<Typography component="h1" variant="h1">
								Page Not Found
							</Typography>
						</Grid>
						<Grid item xs={12} className={classes.linkWrapper}>
							<Link to={'/kr/'}>
								<Button variant="contained" color="primary">
									HOME
								</Button>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Layout>
	);
};

export default KRNotFoundPage;
